<template>
    <div class="p-grid">
        <div class="p-col-6 p-md-3 p-mb-2" v-for="(market, index) in markets" :key="'market' + index">{{ index + 1 }}. {{ market.displayName }}</div>
    </div>
</template>

<script>
import { SportsSettingsApi } from '../../../service/SportSettingsApi';

export default {
    name: 'specialTemplateMarkets',
    data() {
        return {
            SportsSettingsApi: new SportsSettingsApi(),
            markets: [],
        };
    },
    props: {
        rowData: {
            type: Object,
            required: true,
        },
        rowIndex: {
            type: Number,
        },
    },
    mounted() {
        this.getTemplateMarkets();
    },
    methods: {
        getTemplateMarkets() {
            var marketsIds = this.rowData.specialMarketsIds.join();
            this.SportsSettingsApi.getSpecialMarketsFromGroup(marketsIds)
                .then((response) => {
                    this.markets = response.data;
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.circle {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    display: inline-block;
}
</style>
