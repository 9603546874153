<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="card">
                    <TabView>
                        <TabPanel :header="$t('markets.specialMarkets.singleMarkets')">
                            <DataTable
                                :lazy="true"
                                :value="items"
                                :paginator="true"
                                :rows="perPage"
                                :loading="loading"
                                class="p-mt-3"
                                :totalRecords="totalPages"
                                @page="onPage($event)"
                                @sort="onSort($event)"
                                @filter="onFilter($event)"
                                ref="datatable"
                                responsiveLayout="scroll"
                                :paginatorBottom="true"
                                stateStorage="session"
                                stateKey="dt-state-specialMarkets"
                                v-show="filtersInitializationDone"
                            >
                                <template #header>
                                    <div class="p-grid">
                                        <div class="p-input-icon-left p-col-12 p-md-2">
                                            <i class="pi pi-search" />
                                            <InputText @input="search" v-model="searchTerm" :placeholder="$t('search.byName')" style="width: 100%" />
                                        </div>
                                        <div class="p-col-12 p-md-2">
                                            <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="displayName" :placeholder="$t('search.bySport')" :filter="!this.isMobile()" />
                                        </div>
                                        <div class="p-col-12 p-md-2 p-md-offset-6 p-text-right"><Button :label="$t('buttons.add')" class="p-button-raised p-button-success" @click="goto(editPage, { id: 0 })" /></div>
                                    </div>
                                </template>
                                <Column
                                    ><template #body="slotProps">
                                        <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                                    </template>
                                </Column>
                                <Column field="marketId" :header="$t('sportsSettings.id')" :sortable="true"></Column>
                                <Column field="displayName" :header="$t('sportsSettings.displayName')" :sortable="true"></Column>
                                <Column field="sortNo" :header="$t('sportsSettings.sortOrder')" :sortable="true"></Column>
                                <Column field="groups" :header="$t('sportsSettings.groups')">
                                    <template #body="slotProps">{{ slotProps.data.groups != null ? slotProps.data.groups.join(',') : '' }} </template>
                                </Column>
                                <Column field="displayCols" :header="$t('sportsSettings.displayColumns')" :sortable="true"></Column>
                                <Column :header="$t('sportsSettings.sport')">
                                    <template #body="slotProps"> {{ sportsDictionary[slotProps.data.sportId] }}</template></Column
                                >
                                <Column :exportable="false" class="p-text-right">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="goto(editPage, { id: slotProps.data._id })" />
                                    </template>
                                </Column> </DataTable
                        ></TabPanel>
                        <TabPanel :header="$t('markets.specialMarkets.templates')">
                            <Fieldset :legend="$t('markets.specialMarkets.addNewTemplate')" :toggleable="true" :collapsed="true">
                                <div class="p-grid">
                                    <div class="p-col-12 p-grid">
                                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('sportsSettings.name') }} *</label>
                                        <div class="p-col-12 p-md-4">
                                            <InputText style="width: 100%" type="text" v-model="newTemplateName" />
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-grid">
                                        <label for="displayName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('sportsSettings.sport') }} *</label>
                                        <div class="p-col-12 p-md-4">
                                            <Dropdown style="width: 100%" v-model="templateSportModel" :options="sports" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.sport')" :filter="!this.isMobile()" />
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-grid">
                                        <label for="displayName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('menu.specialMarkets') }} *</label>
                                        <div class="p-col-12 p-md-4">
                                            <MultiSelect style="width: 100%" v-model="specialMarketsDdlModel" :options="specialMarketsDdl" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.markets')" :filter="!this.isMobile()" />
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-4">
                                        <Button @click="addNewTemplate()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                                    </div>
                                    <transition-group name="p-message" tag="div">
                                        <Message :key="errorKeyCount" :severity="'error'" v-show="showNewTemplateError">{{ errorText }}</Message>
                                    </transition-group>
                                </div>
                            </Fieldset>
                            <DataTable :value="templates" v-model:expandedRows="expandedRows" :loading="loading" class="p-mt-3" ref="datatableTemplates" responsiveLayout="scroll">
                                <Column :expander="true" headerStyle="width: 3rem" />
                                <Column field="name" :header="$t('sportsSettings.name')" :sortable="true"></Column>
                                <Column field="specialMarketsIds" :header="$t('sportsSettings.marketsNo')">
                                    <template #body="slotProps">{{ slotProps.data.specialMarketsIds != null ? slotProps.data.specialMarketsIds.length : 0 }} </template>
                                </Column>
                                <Column :header="$t('sportsSettings.sport')">
                                    <template #body="slotProps"> {{ sportsDictionary[slotProps.data.sportId] }}</template></Column
                                >
                                <template #expansion="slotProps">
                                    <SpecialTemplateMarkets :rowData="slotProps.data"></SpecialTemplateMarkets>
                                </template>
                            </DataTable>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
import { uiSettings } from '../../settings/uiSettings';
import { store } from '../../store/index';
import { debounce } from 'lodash';
import SpecialTemplateMarkets from './Components/SpecialTemplatesMarkets.vue';
export default {
    components: {
        SpecialTemplateMarkets,
    },
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            SportsSettingsApi: new SportsSettingsApi(),
            searchTerm: '',
            sort: '',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            crtRoute: 'specialMarkets',
            sports: [],
            sportsModel: { displayName: 'All', id: '0' },
            sportsDictionary: {},
            defaultSportsModel: { displayName: 'All', id: '0' },
            filtersInitializationDone: false,
            templates: [],
            expandedRows: [],
            templateSportModel: {},
            specialMarketsDdl: [],
            specialMarketsDdlModel: [],
            showMarketsDdl: false,
            newTemplateName: '',
            showNewTemplateError: false,
            errorText: '',
            errorKeyCount: 0,
        };
    },
    computed: {
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
        editPage() {
            return this.crtRoute + '/edit';
        },
    },
    mounted() {
        this.SportsSettingsApi.getAllSports()
            .then((response) => {
                this.sports = response.data;
                this.sports.unshift(this.defaultSportsModel);
                this.createSportsDictionary();
                if (this.storeFilterData != undefined) {
                    this.searchTerm = this.storeFilterData.data.search;
                    this.sort = this.storeFilterData.data.sort;
                    this.sportsModel = this.sports.find((s) => s.id == this.storeFilterData.data.sportId);
                    this.readItems(this.storeFilterData.data.pageNo);
                } else {
                    this.readItems();
                }
                this.getSpecialMarketsTemplates();
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    this.$store.commit('login/endUserSession');
                    this.goto('login', { session: 'false' });
                }
            });
    },
    watch: {
        sportsModel: function () {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
        templateSportModel() {
            this.getSpecialMarketsDdl();
        },
    },
    methods: {
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;
            var params = {
                per_page: this.perPage,
                page: page,
                search: this.searchTerm,
                sort: this.sort,
                sportId: this.sportsModel != undefined ? this.sportsModel.id : this.defaultSportsModel.id,
            };
            this.SportsSettingsApi.getSpecialMarkets(params).then((response) => {
                this.items = response.data.data;
                this.totalPages = response.data.total;
                this.loading = false;
                this.filtersInitializationDone = true;
            });
        },
        onPage(event) {
            this.readItems(event.page + 1);
            this.composeAndSaveFiltersToStore();
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.sort = event.sortField + '|' + sortOrder;
            this.readItems();
            this.composeAndSaveFiltersToStore();
        },
        onSortTemplates() {},
        onFilter() {},
        composeAndSaveFiltersToStore() {
            let filters = {
                pageNo: this.crtPage,
                search: this.searchTerm,
                sort: this.sort,
                sportId: this.sportsModel != undefined ? this.sportsModel.id : this.defaultSportsModel.id,
            };
            this.saveFiltersToStore(filters, this.crtRoute);
        },
        search: debounce(function () {
            this.readItems();
        }, 400),
        createSportsDictionary() {
            for (let i = 0; i < this.sports.length; i++) {
                this.sportsDictionary[this.sports[i].id] = this.sports[i].displayName;
            }
        },
        getSpecialMarketsTemplates() {
            this.SportsSettingsApi.getSpecialMarketsGroups('0').then((response) => {
                this.templates = response.data;
                this.awaitTemplates = false;
            });
        },
        getSpecialMarketsDdl() {
            this.SportsSettingsApi.getAllSpecialMarkets(this.templateSportModel.id)
                .then((response) => {
                    this.specialMarketsDdl = response.data;
                    this.showMarketsDdl = true;
                })
                .catch(() => {
                    this.showMarketsDdl = false;
                });
        },
        addNewTemplate() {
            var marketsIds = this.specialMarketsDdlModel.map((item) => {
                return item.marketId;
            });
            var newGroup = {
                Name: this.newTemplateName,
                SportId: this.templateSportModel.id,
                SpecialMarketsIds: marketsIds,
            };
            this.SportsSettingsApi.addSpecialsGroup(newGroup)
                .then(() => {
                    this.clearNewTemplateFields();
                    this.templates = [];
                    this.getSpecialMarketsTemplates();
                })
                .catch((error) => {
                    this.errorText = error;
                    this.showNewTemplateError = true;
                    this.errorKeyCount++;
                });
        },
        clearNewTemplateFields() {
            this.newTemplateName = '';
            this.templateSportModel = {};
            this.specialMarketsDdlModel = [];
        },
    },
};
</script>
